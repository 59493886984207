// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-deviation-reporting-tsx": () => import("./../../../src/pages/deviation-reporting.tsx" /* webpackChunkName: "component---src-pages-deviation-reporting-tsx" */),
  "component---src-pages-environmental-system-tsx": () => import("./../../../src/pages/environmental-system.tsx" /* webpackChunkName: "component---src-pages-environmental-system-tsx" */),
  "component---src-pages-how-to-implement-the-quality-system-in-your-company-tsx": () => import("./../../../src/pages/how-to-implement-the-quality-system-in-your-company.tsx" /* webpackChunkName: "component---src-pages-how-to-implement-the-quality-system-in-your-company-tsx" */),
  "component---src-pages-improving-quality-tsx": () => import("./../../../src/pages/improving-quality.tsx" /* webpackChunkName: "component---src-pages-improving-quality-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-occupational-safety-system-tsx": () => import("./../../../src/pages/occupational-safety-system.tsx" /* webpackChunkName: "component---src-pages-occupational-safety-system-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-quality-manual-tsx": () => import("./../../../src/pages/quality-manual.tsx" /* webpackChunkName: "component---src-pages-quality-manual-tsx" */),
  "component---src-pages-quality-system-tsx": () => import("./../../../src/pages/quality-system.tsx" /* webpackChunkName: "component---src-pages-quality-system-tsx" */),
  "component---src-pages-quality-tsx": () => import("./../../../src/pages/quality.tsx" /* webpackChunkName: "component---src-pages-quality-tsx" */),
  "component---src-pages-software-tsx": () => import("./../../../src/pages/software.tsx" /* webpackChunkName: "component---src-pages-software-tsx" */),
  "component---src-pages-step-by-step-instructions-for-building-quality-system-tsx": () => import("./../../../src/pages/step-by-step-instructions-for-building-quality-system.tsx" /* webpackChunkName: "component---src-pages-step-by-step-instructions-for-building-quality-system-tsx" */),
  "component---src-pages-what-does-it-cost-to-build-quality-system-tsx": () => import("./../../../src/pages/what-does-it-cost-to-build-quality-system.tsx" /* webpackChunkName: "component---src-pages-what-does-it-cost-to-build-quality-system-tsx" */),
  "component---src-pages-what-is-quality-system-tsx": () => import("./../../../src/pages/what-is-quality-system.tsx" /* webpackChunkName: "component---src-pages-what-is-quality-system-tsx" */),
  "component---src-pages-why-do-you-need-quality-system-tsx": () => import("./../../../src/pages/why-do-you-need-quality-system.tsx" /* webpackChunkName: "component---src-pages-why-do-you-need-quality-system-tsx" */)
}

